<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <!-- <v-btn @click="exportCustomerInvoice">export</v-btn> -->
      <v-toolbar-title class="headline font-weight-bold"
        >Invoices ({{ invoiceCount }})</v-toolbar-title
      >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="mr-1" icon @click="exportCustomerInvoice" v-on="on">
            <v-icon>{{ icons.export }}</v-icon>
          </v-btn>
        </template>
        <span>Export to Excel</span>
      </v-tooltip>
    </v-app-bar>

    <div class="mt-12 mx-4">
      <v-row>
        <v-col md="4">
          <v-select
            flat
            hide-details
            solo
            rounded
            :items="statuses"
            :item-text="statuses.text"
            :item-value="statuses.value"
            clearable
            background-color="grey lighten-3"
            label="Filter by Status"
            v-model="filter.status"
            @change="search"
          ></v-select>
        </v-col>
        <v-col md="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-combobox
                v-model="dates"
                multiple
                solo
                rounded
                chips
                flat
                small-chips
                label="Filter Date of Service"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                background-color="grey lighten-3"
                clearable
                v-on="on"
                @click:clear="search"
              ></v-combobox>
            </template>
            <v-date-picker
              v-model="dates"
              @change="search"
              range
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(dates)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <div
        id="invoice-list"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="loading"
        infinite-scroll-distance="100"
      >
        <simple-table
          :loading="loading"
          :headers="tableHeaders"
          :collection="invoices"
          :sort="sort"
          @headerClicked="sortInvoices"
          :enableDetails="false"
          @actionClicked="exportCustomerInvoice"
          @deleteAction="handleDeleteInvoice"
        />
      </div>
    </div>
    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
    <ConfirmDialog ref="confirm" color="error" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFilePdf,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import SimpleTable from '@/components/SimpleTable'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import { has } from 'lodash'
import Invoice from '@/models/Invoice'

export default {
  name: 'InvoicesPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    SimpleTable,
    ConfirmDialog,
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,
      dates: null,
      menu: false,
      statuses: [
        { text: 'Draft', value: 'DRAFT' },
        { text: 'New', value: 'NEW' },
        { text: 'In Progress', value: 'IN_PROGRESS' },
        { text: 'Completed', value: 'COMPLETED' },
        { text: 'Sent', value: 'SENT' },
        { text: 'Received', value: 'RECEIVED' },
        { text: 'Paid', value: 'PAID' },
        { text: 'Unsuccessful', value: 'UNSUCCESSFUL' },
      ],
      filter: {
        search: '',
        status: '',
      },

      sort: '',

      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFilePdf,
      },
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  watch: {
    sort: function (newSortBy, oldSortBy) {
      this.clearInvoices()
      this.fetchInvoices()
    },
  },

  created() {
    this.clearInvoices()
    this.fetchInvoices(1)
  },

  destroyed() {
    this.clearInvoices()
  },

  computed: {
    dateSpan() {
      return this.dates && this.dates[1]
        ? this.dates[0] + ',' + this.dates[1]
        : ''
    },
    ...mapState({
      invoices: (state) => state.invoice.list,
      listMeta: (state) => state.invoice.listMeta,
    }),

    ...mapGetters('invoice', ['invoiceCount']),

    paginationLength() {
      return this.listMeta.last_page | 0
    },

    tableHeaders() {
      return [
        {
          property: 'invoice_number',
          name: 'Invoice Number',
        },
        { property: 'tradie_id', name: 'Tradie ID' },
        { property: 'date_of_service', name: 'Date of Service' },
        { property: 'next_payment_due', name: 'Next Payment Date' },
        { property: 'total_with_gst', name: 'Total Amount (w/ GST)' },
        { property: 'paid', name: 'Paid Amount' },
        { property: 'remaining', name: 'Remaining Amount' },
        { property: 'created_at', name: 'Added Date' },
        {
          property: 'tradie_profile',
          name: 'Tradie Account Details',
          width: '20%',
          sortable: false,
        },
        { property: 'status', status: true },
        {
          property: 'action',
          name: 'Action',
          sortable: false,
          key: 'invoice_id',
          invoice_number: 'invoice_number',
        },
      ]
    },
  },

  methods: {
    ...mapActions({
      getInvoices: 'invoice/getInvoices',
      exportToPDF: 'invoice/exportInvoice',
    }),
    async handleDeleteInvoice(id, invoiceNumber) {
      const hasDialog = has(this.$refs, 'confirm')

      if (hasDialog) {
        const confirm = await this.$refs.confirm.open(
          'Delete Invoice',
          'Do you want to delete this Invoice?'
        )

        const invoice = confirm && new Invoice({ id })

        await invoice.delete().catch((d) => {
          this.showSnackbar(d.response.data.message, 'red')
        })
        this.clearInvoices()
        this.fetchInvoices(1)
      }
    },
    async exportCustomerInvoice(id, invoiceNumber) {
      this.loading = true
      await this.exportToPDF(id)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/pdf',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Invoice-${invoiceNumber}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded', 'success')
        })
        .catch(() => {
          this.showSnackbar('Ops! Something went wrong', 'red')
        })
      this.loading = false
    },

    ...mapMutations({
      clearInvoices: 'invoice/clearInvoiceList',
    }),

    pageChanged(page) {
      this.fetchInvoices(page)
    },

    sortInvoices(property) {
      this.sort = (this.sort.startsWith('-') ? '' : '-') + property
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchInvoices(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearInvoices()
      this.fetchInvoices()
    }, 600),

    async fetchInvoices(page = 1) {
      if (this.loading) return

      const params = {
        page,
        sort: this.sort,
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      if (this.filter.status) {
        params.status = this.filter.status
      }

      params.dateSpan = this.dateSpan

      this.loading = true
      await this.getInvoices(params)
      this.loading = false
    },

    showProfileDialog(profile) {
      this.selectedProfile = profile
      this.showProfile = true
    },
    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
  },
}
</script>
